<template>
    <div class=" d-flex justify-center">
      <div class="all-width">
        <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
          <v-card-text>
            <div class="d-flex align-center mb-1">
                <div class="">
                    <div class="d-flex">
                        <p class="mb-1">Home</p>
                        <p class="px-2 mb-1">/</p>
                        <p class="mb-1">{{ metadata.description }}</p>
                    </div>
                    <div class="d-flex align-center justify-start">
                        <p class="black--text ma-0  pt-1 font-weight-bold text-h5">Users</p>
                        <v-tooltip bottom content-class="tooltip-bottom" >
                          <template v-slot:activator="{ on, attrs }"> 
                              <v-btn 
                                  v-bind="attrs"
                                  v-on="on"
                                  class="shadow-off ml-1 pt-1" icon @click="refreshData()"><v-icon
                                  size="26">mdi-refresh</v-icon>
                              </v-btn>
                          </template>
                          <span>Refresh</span>
                        </v-tooltip>
                          <lb-activitysidemenuNew class="mt-1" :config="activitylogconfig" :url="`/v2/financialclose/project/getusermanagelogs/${metadata._id}`" />
                    </div>
                </div>

            </div>
            <div v-if="loading && !metadata._id">
              <v-skeleton-loader
                type="table-heading, list-item-two-line, article, article"
              ></v-skeleton-loader>
            </div>
            <div class="" v-else>
              <div class="mb-8">
                <div class="d-flex  align-center">
                  <div class="text-subtitle-1 black--text font-weight-bold">Associated Users</div>
                  <v-spacer></v-spacer>
                  <v-btn small color="black" height="4vh"  class="white--text mb-1 radius-small shadow-off" @click="associateUserDialogBox()" v-if="managerlist.indexOf(userid) > -1 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"><v-icon class="mr-1">mdi-plus</v-icon>User</v-btn>
                </div>
                <lb-datatableaction v-if="formatedData.length>0" :headers="tableheaders" :tabledata="formatedData"
                :itemsperpage="itemsPerPage" searchterm="" :bulkActions="false">

                <template v-slot:tbody>
                    <tr v-for="item in formatedData" :key="item._id" class=""
                    :class="{ 'low-opacity': item.status === false }">
                    <template v-for="header in tableheaders">
                      <td  v-if="header.visible && header.value !== 'action'" class="px-2 single-line" :key="header.value"
                      :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                      <div v-if="header.value === 'type'">
                        <v-chip small label v-if="item.type === 'manager'" color="lightpurple" class="font-weight-bold caccounting--text">Manager</v-chip>
                        <v-chip small label v-else-if="item.type === 'general'" color="lightgreen" class="font-weight-bold green--text">General</v-chip>
                        <v-chip small label v-else color="lightred" class="error--text font-weight-bold">Unknown</v-chip>
                      </div>
                      <div  v-else-if="header.value === 'tasklist'">
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="(item.tasklist || {}).preparer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightblue" class="blue--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.tasklist.preparer}}</v-chip>
                          </template>
                          <span>Preparer</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="(item.tasklist || {}).approver">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightyellow" class="yellow--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.tasklist.approver}}</v-chip>
                          </template>
                          <span>Approver</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value === 'progress'">
                        <div v-if="(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0)) > 0" style="max-width:100px;margin:auto">
                          {{ (((((item.progress || {}).completed || 0)/(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0))) || 0)*100).toFixed(0) }}%<v-progress-linear
                            :height="10" color="green"
                            :value="Math.max(((((item.progress || {}).completed || 0)/(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0))) || 0)*100,2)">
                          </v-progress-linear>
                        </div>
                        <div v-else></div>
                      </div>
                      <span v-else> {{ getItemData(item, header.value) }}</span>
                      </td>
                    </template>
                    <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                      <div class="" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                        <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid && managerlist.indexOf(userid) > -1" >
                         <template v-slot:activator="{ on, attrs }">
                           <v-btn small icon v-bind="attrs" v-on="on" @click="manageUser('move', item.type, item._id)"><v-icon>mdi-sync</v-icon></v-btn>
                         </template>
                         <span v-if="item.type === 'general'">Change to Manager User</span>
                         <span v-else>Change to General User</span>
                       </v-tooltip>
                       <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid && managerlist.indexOf(userid) > -1">
                         <template v-slot:activator="{ on, attrs }">
                           <v-btn small icon v-bind="attrs" v-on="on" @click="deleteUserDialogBox(item)"><v-icon>mdi-close</v-icon></v-btn>
                         </template>
                         <span>Remove User</span>
                       </v-tooltip>                          
                       <v-tooltip bottom content-class="tooltip-bottom" v-if="managerlist.indexOf(userid) > -1">
                         <template v-slot:activator="{ on, attrs }">
                           <v-btn small icon v-bind="attrs" v-on="on" @click="replaceUserDialog(item._id, item.type)"><v-icon>mdi-account-convert</v-icon></v-btn>
                         </template>
                         <span>Replace User</span>
                       </v-tooltip>     
                       </div>
                    </td>
                    </tr>
                </template>

                </lb-datatableaction>
                <div class="d-flex align-center justify-center" v-else>
                  NO DATA
                </div>
                <div class="d-flex py-2 align-center">
                  <div class="text-subtitle-1 black--text font-weight-bold">Information Owners</div>
                  <v-spacer></v-spacer>
                </div>

                <lb-datatableaction v-if="formatedDataOne.length>0" :headers="headersOne" :tabledata="formatedDataOne"
                :itemsperpage="itemsPerPage" searchterm="" :bulkActions="false">

                <template v-slot:tbody>
                    <tr v-for="item in formatedDataOne" :key="item._id" class=""
                    :class="{ 'low-opacity': item.status === false }">
                    <template v-for="header in headersOne">
                      <td  v-if="header.visible && header.value !== 'action'" class="px-2 single-line" :key="header.value"
                      :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                      <div v-if="header.value === 'progress' " style="max-width:100px;margin:auto">
                        {{ ((((item.approved)/(item.requested+item.pending+item.approved+item.cancelled)) || 0)*100).toFixed(0) }}%
                        <v-progress-linear
                          :height="10" color="green"
                          :value="Math.max((((item.approved)/(item.requested+item.pending+item.approved+item.cancelled)) || 0)*100,2)">
                        </v-progress-linear>
                       </div>

                       <div class="" v-else-if="header.value === 'information'">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightpurple" class="caccounting--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.requested+item.pending+item.approved+item.cancelled}}</v-chip>
                          </template>
                          <span>Total requests</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightblue" class="blue--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.requested}}</v-chip>
                          </template>
                          <span>Yet to respond</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightyellow" class="yellow--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.pending}}</v-chip>
                          </template>
                          <span>Waiting for approval</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightgreen" class="green--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.approved}}</v-chip>
                          </template>
                          <span>Approved</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip label small color="lightred" class="error--text mr-1 font-weight-bold" v-bind="attrs" v-on="on">{{item.cancelled}}</v-chip>
                          </template>
                          <span>Cancelled</span>
                        </v-tooltip>
                       </div>
                       <span v-else> {{ getItemData(item, header.value) }}</span>
                      </td>
                    </template>
                    <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                      <v-tooltip bottom content-class="tooltip-bottom" v-if="managerlist.indexOf(userid) > -1 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon v-bind="attrs" v-on="on" @click="replaceInfoOwnerDialog(item)"><v-icon>mdi-account-convert</v-icon></v-btn>
                        </template>
                        <span>Replace Information Owner</span>
                      </v-tooltip>
                    </td>
                    </tr>
                </template>

                </lb-datatableaction>
                <div class="d-flex align-center justify-center" v-else>
                  NO DATA
                </div>
           
              </div>
            </div>
  
          </v-card-text>
        </v-card>
  
        <lb-drawer v-model="infoownerdialog" heading="Change Information Owner" width="400" :loading="infoownerloading">
          <template v-slot:body>
            <div>
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown v-model="infoownerchangetype" label="Type*" :items="[{name: 'New', value: 'new'},{name: 'Existing', value: 'existing'}]" itemtext="name"
                    itemvalue="value" />
                </v-col>
                <v-col cols="12" class="my-0 py-1" v-if="infoownerchangetype === 'existing'">
                  <lb-dropdown label="New Owner*" v-model="infoownerdata.newownerid" :items="possibleinfoowners" itemtext="name"
                    itemvalue="_id" />
                </v-col>
                <v-col cols="12" class="my-0 py-1" v-if="infoownerchangetype === 'new'">
                  <lb-string label="Name*" v-model="infoownerdata.name"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1" v-if="infoownerchangetype === 'new'">
                  <lb-string label="Email*" v-model="infoownerdata.email"/>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" small @click="replaceInfoOwner()" :disabled="!(infoownerdata.newownerid || (infoownerdata.name && infoownerdata.email))">Update</v-btn>
          </template>
        </lb-drawer>
  
        <lb-drawer v-model="associateuserdialog" heading="Add User" width="400" :loading="associateuserloading">
          <template v-slot:body>
            <div>
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="User*" v-model="associateuserdata.userid" :items="associateuserlist" itemtext="name"
                    itemvalue="_id" />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown v-model="associateuserdata.type" label="Type*" :items="usertypes" itemtext="name"
                    itemvalue="value" />
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" small @click="manageUser('associate')" :disabled="!associateuserdata.type || !associateuserdata.userid">Add</v-btn>
          </template>
        </lb-drawer>
    
        <lb-drawer v-model="replaceuserdialog" heading="Replace User" width="500" :loading="replaceuserloading">
          <template v-slot:body>
            <div>
              <div class="mb-3">
                <span class="font-weight-bold mr-2">From User:</span>
                <span class="">{{(userdata[replaceuserid] || {}).displayname || 'Unknown'}}</span>
              </div>
              <lb-dropdown label="To User*" v-model="replaceuserdata.userid" :items="replaceuserlist" itemtext="displayname" itemvalue="_id" />
              <div v-if="(userchecklistcount[replaceuserid] || {}).preparer || (userchecklistcount[replaceuserid] || {}).approver">
                <div>Since the user is also associated to various tasklist items, all those association will be replaced with the new user.</div>
                <div><span class="font-weight-bold mr-2">Note:</span>If the new user is already assigned to the tasklist item in a different capacity, then they will not be re-assigned.</div>
                <div class="font-weight-bold">Do you want to proceed?</div>
              </div>
            </div>
          </template>
          <template v-slot:actions>
            <span class="caption">*Required</span>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" small @click="replaceUser()" :disabled="!replaceuserdata.userid">Replace</v-btn>
          </template>
        </lb-drawer>
  
        <lb-drawer v-model="deleteuserdialog" heading="Remove User" width="500" :loading="deleteuserloading">
          <template v-slot:body>
            <div>
              <div class="mb-3">
                <span class="font-weight-bold mr-2">Selected User:</span>
                <span class="">{{(deleteuserdata || {}).name || 'Unknown'}}</span>
              </div>
              <div v-if="(userchecklistcount[deleteuserdata._id] || {}).preparer || (userchecklistcount[deleteuserdata._id] || {}).approver">
                Since the user is also associated to various tasklist items, all those association will be removed if the user is removed from the project.
              </div>
              <div v-else>This will remove the user from the current project.</div>
              <div class="font-weight-bold">Do you want to proceed?</div>
            </div>
          </template>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" small @click="manageUser('remove', deleteuserdata.type, deleteuserdata._id)()">Remove</v-btn>
          </template>
        </lb-drawer>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'financialclose_project_users',
    // props: ['props'],
    data: function(){
      return {
        right:"financialclose_project",
        metadata: {},
        checklist: [],
        pbcdata: {},
        userchecklistcount: {},
        userid: "",
        formdata: {},
        userdata: {},
        stage: -1,      
        id: "",
        loading: false,
  
        associateuserdialog: false,
        associateuserloading: false,
        associateuserdata: {},
        associateuserlist: [],
        infoownerchangetype: "",
        oldinfoownerdata: {},
        infoownerdialog: false,
        infoownerloading: false,
        infoownerdata: {},
        allinfoowners: [],
        userlist: [],
        managerlist: [],
        infoowners: [],
        possibleinfoowners: [],
        replaceuserdialog: false,
        replaceuserloading: false,
        replaceuserid: "",
        replaceuserdata: {},
        replaceuserlist: [],
        deleteuserdialog: false,
        deleteuserloading: false,
        deleteuserdata: {},
        usertypes: [
          { name: "General user", value: "general" },
          { name: "Manager user", value: "manager" }
        ],
        tableheaders: [
          {
            value: "indexno",
            text: "SL No",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "name",
            text: "Name",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "email",
            text: "Email",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "type",
            text: "Type",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "tasklist",
            text: "Tasklist Items",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "progress",
            text: "Progress",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "action",
            text: "Action",
            sortable: false,
            filterable: false,
            visible:true,
          }
        ],
        headersOne: [
          {
            value: "indexno",
            text: "SL No",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "name",
            text: "Name",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "email",
            text: "Email",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "information",
            text: "Information Requests",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "progress",
            text: "Progress",
            sortable: false,
            filterable: false,
            visible:true,
          },
          {
            value: "action",
            text: "Action",
            sortable: false,
            filterable: false,
            visible:true,
          }
        ],
        formatedData: [],
        formatedDataOne: [], 
        headersPushed: false,
        activitylogconfig: {
          userassociate: {
            icon: "mdi-plus",
            color: "info",
            displaytext: "User Associated",
          },
          userreplaced: {
            icon: "mdi-account-convert",
            color: "info",
            displaytext: "User Replaced",
          },
          userremoved: {
            icon: "mdi-close",
            color: "error",
            displaytext: "User Removed",
          },
          usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
        },
        itemsPerPage:10
      }
    },
    components: {
    },
    created() {
      this.refreshData();
    },
    activated() {
      this.refreshData();
    },
    methods: {
      getItemData(item, key) {
        return item[key];
      },
      formateData() {
        let ObjectTemp = {};
        let ArrayTemp = [];
        this.finaluserlist.forEach((item, index) => {
          ObjectTemp = {
            __key: index,
            __formatedkey: index,
            _id: item._id,
            indexno: index + 1,
            name: item.name,
            email: item.email,
            color: item.color,
            type: item.type,
            tasklist: this.userchecklistcount[item._id],
            progress: this.userchecklistcount[item._id],
          };
          ArrayTemp.push(ObjectTemp);
        });
        this.formatedData = ArrayTemp;
      },
      formateDataOne(){
        let ObjectTempone = {};
        let ArrayTempone = [];
        this.infoowners.forEach((item, index) => {
          ObjectTempone = {
            __key: index,
            __formatedkey: index,
            _id: item._id,
            indexno: index + 1,
            name: item.info_owner_detail.name,
            email: item.info_owner_detail.email,
            approved:item.approved,
            cancelled:item.cancelled,
            pending:item.pending,
            requested:item.requested,
            info_owner_detail:item.info_owner_detail,
            info_owner:item.info_owner,
          };
          ArrayTempone.push(ObjectTempone);
        });
        
        this.formatedDataOne = ArrayTempone;
      },
      refreshData() {
        this.id = this.$route.params.id || "";
        this.userid = this.$store.state.user.id;
        this.getData();
      },
      getData() {
        this.loading = true;
        return this.axios.post("/v2/financialclose/analysis/getuserdetails/"+this.id).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            let data = dt.data.data[0] || {}
            this.metadata = data.metadata || {};
            this.userlist = [];
            this.userlist = this.metadata.userlist || [];
            this.managerlist = [];
            this.managerlist = this.metadata.managerlist || [];
            this.stage = this.metadata.stage;
            this.userchecklistcount = data.userchecklistcount || {};
            this.infoowners = data.infoowners || [];
            this.formateDataOne();
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).then(() => {
          return this.axios.post("/v2/financialclose/analysis/getusersformdata");
        }).then(dt => {
          if(dt.data.status === "success"){
            this.formdata = dt.data.data[0] || {};
            this.allinfoowners = this.formdata.infoowners || [];
            console.log(this.allinfoowners);
            this.userdata = {};
            let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userdata[el._id] = el;
              this.userdata[el._id].color = colors[i];            
            }
            this.formateData();
          }else throw new Error(dt.data.message);
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
        })
      },
      deleteUserDialogBox(data) {
        this.deleteuserdata = {...data};
        this.deleteuserdialog = true;
      },
      associateUserDialogBox() {
        this.associateuserdata = {};
        this.associateuserlist = [];
        for (const k in this.formdata.users) {
          if (Object.hasOwnProperty.call(this.formdata.users, k)) {
            const el = this.formdata.users[k];
            if(this.managerlist.indexOf(el._id) === -1 && this.userlist.indexOf(el._id) === -1) this.associateuserlist.push(el);
          }
        }
        this.associateuserdialog = true;
      },
      replaceInfoOwnerDialog(d) {
        this.infoownerdata = {};
        this.oldinfoownerdata = d;
        this.possibleinfoowners = [];
        for (const i of this.allinfoowners) {
          if(i._id !== d._id) this.possibleinfoowners.push(i);
        }
        this.infoownerchangetype = "existing";
        this.infoownerdialog = true;
      },
      replaceInfoOwner() {
        this.infoownerdata.oldownerid = this.oldinfoownerdata._id;
        this.infoownerdata.type = this.infoownerchangetype;
        this.infoownerloading = true;
        this.axios.post("/v2/financialclose/project/manageinfoowner/" + this.metadata.pbcproject, {data: [this.infoownerdata]}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Information owner changed");
            this.getData()
          }else throw dt.data.message || "Error changing status";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.infoownerloading = false;
          this.infoownerdialog = false;
        });
      },
  
      manageUser(type, usertype = "general", uid = "", fromuserid = "") {
        let data = {};
        if (type === "associate") {
          this.associateuserloading = true;
          data = { userid: this.associateuserdata.userid, usertype: this.associateuserdata.type, type: "associate" };
        } else if (type === "move") {
          this.loading = true;
          data = { userid: uid, usertype: usertype, type: "move" };
        } else if (type === "remove") {
          this.deleteuserloading = true;
          data = { userid: uid, usertype: usertype, type: "remove" };
        } else if (type === "replace") {
          this.deleteuserloading = true;
          this.replaceuserloading = true;
          data = { touserid: uid, usertype: usertype, type: "replace", fromuserid: fromuserid };
        }
        let successflag = false;
        console.log(data,"data");
        this.axios.post("/v2/financialclose/project/manageuser/" + this.metadata._id, { data: [data] })
          .then(dt => {
            if (dt.data.status === "success") {
              this.associateuserdialog = false;
              this.deleteuserdialog = false;
              const successMessages = {
                associate: "User Associated",
                move: "User Changed",
                remove: "User Removed",
                replace: "User Replaced User"
              };
              this.$store.commit("sbSuccess", successMessages[type]);
              if (type === "replace") {
                this.replaceuserloading = false;
                this.replaceuserdialog = false;
                successflag = true;
              }
              this.refreshData();
            } else {
              throw new Error(dt.data.message || "Error changing status");
            }
          })
          .catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            this.deleteuserloading = false;
            this.associateuserloading = false;
            if (successflag) this.refreshData();
          });
    },
  
      replaceUserDialog(uid, type) {
        this.replaceuserdata = {type: type};
        this.replaceuserlist = [];
        this.replaceuserid = uid;
        for (const k in this.formdata.users) {
          if (Object.hasOwnProperty.call(this.formdata.users, k)) {
            const el = this.formdata.users[k];
            if(el._id !== uid) this.replaceuserlist.push(el);
          }
        }
        this.replaceuserdialog = true;
      },
       replaceUser(){
        let fromuserid = this.replaceuserid;
        let touserid = this.replaceuserdata.userid;
        let type = this.replaceuserdata.type;
        this.manageUser("replace",type,touserid,fromuserid)
      },

     
    },
    computed: {
      finaluserlist(){
        let nl = [];
        for (const i of this.managerlist) {
          let ud = {...this.userdata[i]};
          ud.type = "manager";
          nl.push(ud);
        }
        for (const i of this.userlist) {
          let ud = {...this.userdata[i]};
          ud.type = "general";
          nl.push(ud);
        }
        return nl;
      }
    },
    watch: {
      "infoownerchangetype": function(d){
        this.infoownerdata.type = d;
      }
    }
  }
  </script>
  